<template>
  <div class="bid-dialog">
    <v-form v-model="valid" @submit.prevent="nextHandler">
      <div class="px-10 text-center">
        <span class="mt-10 d-block font-20 black--text font-500">
          {{ $t("enterYourPrice") }}</span
        >
        <!-- Please Enter your Price $ your Date for this shipment</span -->
        <v-row>
          <v-col cols="12">
            <h6 class="text-start" style="position: relative; top: 40px">
              {{ $t("price") }}
            </h6>
            <base-input
              class="mt-10"
              :placeholder="$t('price')"
              v-model="obj.price_shipment"
              :isBudget="true"
              type="number"
            ></base-input>
          </v-col>
          <v-col cols="12"  >
            <h6 class="text-start" style="position: relative; top: 20px">
              {{ $t("trip") }}
            </h6>
            <base-select
              class="my-5"
              :items="trips"
              itemValue="id"
              :placeholder="$t('trip')"
              v-model="obj.trip_id"
            ></base-select>
          </v-col>
          <v-col cols="12" v-if="trips.length == 0">
            <v-btn width="100%" color="primary" @click="$router.push({name:'Add Trip'})" > {{$t('addTrip')}}</v-btn>
          </v-col>
        </v-row>

        <div class="text-center my-10">
          <v-btn
            class="c-btn mx-1"
            color="grey"
            height="40"
            @click="$emit('cancel')"
            ><span class="white--text font-20 font-300 d-inline-block px-3">{{
              $t("cancel")
            }}</span></v-btn
          >
          <v-btn
            class="c-btn mx-1"
            color="primary"
            height="40"
            :disabled="!valid"
            type="submit"
            ><span class="white--text font-300 font-20 d-inline-block px-10">{{
              $t("ok")
            }}</span></v-btn
          >
        </div>
      </div>
    </v-form>
  </div>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
      
    },
  },
  data: () => ({
    valid: false,
    obj: { price_shipment: "" },
    trips: [],
    loading:false,
  }),
  methods: {
    async getTrips() {
      this.loading=true
      await this.$axios.get("/getMyTripsWithDate").then((data) => {
        if (data) {
          this.loading=false
          this.trips = [
            ...data.data.data.trips.map((item) => {
              return {
                id: item.id,
                name: `${item.expected_date}  [ ${item.origin_country}, ${item.destination_country} ]`,
              };
            }),
          ];
        }
      });
    },
    nextHandler() {
      this.$emit("next", this.obj);
      console.log(this.obj);
    },
  },
  created() {
    this.getTrips();
  },
};
</script>

<style lang="scss"></style>
