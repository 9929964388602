<template>
  <div class="message-dialog relative over-hidden">
    <div class="close-icon">
      <v-icon color="primary" @click="$emit('close')">mdi-close</v-icon>
    </div>
    <v-form @submit.prevent="sendMessage" ref="messageForm">
      <div class="px-10 text-center">
        <div class="mt-5">
          <span class="font-30 primary--text font-500"
            >{{ $t("sendMessage") }}:
          </span>
          <span class="font-30 primary--text font-500">{{
            user.sender_name || user.bringer_name
          }}</span>
        </div>
        <div
          class="user-avatar d-flex align-center pb-3"
          v-if="user && user.image_profile"
        >
          <img
            class="mr-3"
            height="60"
            width="60"
            alt=""
            :src="
              user.image_profile.length > 1
                ? user.image_profile
                : require('@/assets/images/global/user-default.jpg')
            "
          />
          <span class="font-18 black--text mx-5">{{ user.sender_name }}</span>
        </div>
        <v-row>
          <v-col cols="12">
            <base-textarea
              class="mt-5"
              :placeholder="$t('navbar.message')"
              v-model="message"
              :rows="5"
            ></base-textarea>
          </v-col>
        </v-row>

        <v-btn
          class="c-btn my-10"
          style="background-color: $primary"
          color="primary"
          min-width="240"
          height="40"
          type="submit"
          ><span class="white--text font-300 font-20">{{
            $t("send")
          }}</span></v-btn
        >
      </div>
    </v-form>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      default: () => {},
    },
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    message: "",
  }),
  methods: {
    async sendMessage() {
      let valid = this.$refs.messageForm.validate();
      if (!valid) return;
      let formData = new FormData();
      formData.append("message", this.message);
      formData.append("receiver_id", this.user.owner_id);
      const res = await this.$axios.post("/sendMessage", formData);
      if (res.data) {
        this.$store.dispatch("showSnack", {
          text: res.data.message,
          color: "success",
        });
        this.$emit("close");
        this.$router.replace(`/profile/chat/${this.user.owner_id}`);
      } else {
        this.$store.dispatch("showSnack", {
          text: res.message,
          color: "error",
        });
      }
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.user-avatar {
  border-bottom: 1px solid rgba(red, 0.7);
  width: 100%;
  img {
    border-radius: 50%;
    overflow: hidden;
  }
}
</style>
