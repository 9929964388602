<template>
  <div class="message-dialog  relative over-hidden">
    <div class="close-icon">
      <v-icon color="primary" @click="$emit('close')">mdi-close</v-icon>
    </div>
    <v-form @submit.prevent="submitHandler" ref="reportForm" v-model="valid">
      <div class="px-10 text-center pb-10">
        <div class="mt-5">
          <span class="d-block font-30 primary--text font-500"
            >{{$t('reportTitle')}}:</span
          >
        </div>
        <v-row class="text-center" justify="center">
          <v-col cols="12">
            <base-textarea
              class="mt-4"
              :placeholder="$t('reportMessage')"
              v-model="message"
              :rows="5"
            ></base-textarea>
          </v-col>
        </v-row>

        <div class="d-flex align-center justify-center mt-7">
          <v-btn
            class="c-btn mx-1"
            color="grey"
            min-width="110"
            height="35"
            @click="$emit('close')"
            ><span class="white--text font-300 font-20">{{
              $t("close")
            }}</span></v-btn
          >
          <v-btn
            class="c-btn mx-1"
            color="primary"
            min-width="110"
            height="35"
            type="submit"
            ><span class="white--text font-300 font-20">{{
              $t("send")
            }}</span></v-btn
          >
        </div>
      </div>
    </v-form>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      default: () => {},
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    message: "",
    valid: false,
  }),
  methods: {
    async submitHandler() {
      let valid = this.$refs.reportForm.validate();
      if (!valid) return;
      let formData = new FormData();
      formData.append("shipment_id", this.item.id);
      formData.append("reason", this.message);
      const res = await this.$axios.post("/reportShipment", formData);
      if (res.data) {
        this.$store.dispatch("showSnack", {
          text: res.data.message,
          color: "success",
        });
        this.$emit("close");
      } else {
        this.$store.dispatch("showSnack", {
          text: res.message,
          color: "error",
        });
      }
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.user-avatar {
  border-bottom: 1px solid rgba(red, 0.7);
  width: 100%;
  img {
    border-radius: 50%;
    overflow: hidden;
  }
}
</style>
