<template>
  <div class="details-ship" style="background-color: #fff">
    <div v-if="loading" style="min-height: 300px; display: flex; justify-content: center; align-items: center; background-color: rgba(0, 0, 0, 0.1);">
      <v-progress-circular  :size="40" :width="3" color="primary" indeterminate>
      </v-progress-circular>
    </div>
    <div v-else class="max-width">
      <div>
        <h2 class="primary--text py-14">{{ $t("shipmentDetails") }}</h2>
      </div>
      <v-row align="start">
        <v-col cols="12" md="3">
          <v-card
            aling="center"
            height="270px"
            style="box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.09)"
          >
            <div class="w-100 d-flex justify-center">
              <div class="mt-5">
                <v-carousel :show-arrows="false" height="230" width="180">
                  <v-carousel-item
                    height="230"
                    width="240"
                    class="object-cover radius-15 h-50"
                    :key="1"
                    :src="data.image1 || data.image"
                  ></v-carousel-item>
                </v-carousel>
              </div>
            </div>
          </v-card>
        </v-col>

        <v-col cols="12" md="5" class="px-4">
          <!--  title -->
          <v-row class="">
            <v-col cols="12">
              <div class="my-3 d-flex align-center">
                <span class="d-block font-30 font-500 primary--text uppercase"
                  >{{ data.title.slice(0, 35) }}
                </span>
                <!-- <span
                  class="d-block font-30 font-500 primary--text ml-2"
                  v-if="data.price"
                  >({{ data.price }}$)
                </span> -->
              </div>
              <div class="">
                <span class="d-block font-30 font-500 lightGreyText--text"
                  >{{ data.sender_name }}
                </span>
              </div>
            </v-col>
          </v-row>

          <!-- category -->
          <!-- <v-row align="center" class="py-0 my-1">
            <v-col cols="2" class="py-0 mt-1 mb-2">
              <span class="font-20 d-block black--text font-500">{{
                $t("category")
              }}</span>
            </v-col>
            <v-col cols="10" class="py-0 mt-1 mb-2">
              <div
                class="text-center py-2 c-btn white dark-shadow"
                v-if="data.category"
              >
                <span class="font-18 black--text">{{
                  data.category.name
                }}</span>
              </div>
            </v-col>
          </v-row> -->
          <!-- weight -->
          <v-row align="center" class="pb-3 pt-0">
            <!-- <v-col>
              <span class="font-20 d-block black--text font-500"
                >{{ $t("weight") }}
              </span>
            </v-col> -->
            <v-col cols="4">
              <div class="d-flex align-center">
                <img
                  width="20"
                  height="20"
                  :src="require('@/assets/images/icons/weight.png')"
                  alt=""
                />
                <span class="font-20 mx-2 d-block black--text font-500"
                  >{{ data.weight + $t("kg") }}
                </span>
              </div>
            </v-col>

            <v-col cols="4">
              <div class="d-flex align-center">
                <img
                  width="18"
                  height="18"
                  :src="require('@/assets/images/icons/prise.png')"
                  alt=""
                />
                <span class="font-20 mx-2 d-block black--text font-500"
                  >{{ data.budget }}$
                </span>
              </div>
            </v-col>
          </v-row>

          <!-- date -->
          <!-- <v-row align="center" class="py-0 my-1">
            <v-col cols="2" class="py-0 mt-1 mb-2">
              <span class="font-20 d-block black--text font-500">{{ $t("date") }} </span>
            </v-col>
            <v-col cols="10" class="py-0 mt-1 mb-2">
              <div class="text-center py-2 c-btn white dark-shadow">
                <span class="font-18 black--text">{{ data.date }} </span>
              </div>
            </v-col>
          </v-row> -->

          <!-- budget -->
          <!-- <v-row align="center" class="py-0 my-1">
            <v-col cols="2" class="py-0 mt-1 mb-2">
              <span class="font-20 d-block black--text font-500"
                >{{ $t("budget") }}
              </span>
            </v-col>
            <v-col cols="10" class="py-0 mt-1 mb-2">
              <div class="text-center py-2 c-btn white dark-shadow">
                <span class="font-18 black--text">{{ data.budget }} </span>
              </div>
            </v-col>
          </v-row> -->

          <!-- note -->
          <v-row align="center" class="py-0 my-1">
            <v-col cols="12" class="py-0 mt-1">
              <div class="d-flex align-center">
                <img
                  width="15"
                  height="15"
                  :src="require('@/assets/images/icons/notes.png')"
                  alt=""
                />
                <span class="font-20 mx-2 d-block black--text font-500">
                  {{ $t("note") }}</span
                >
              </div>
            </v-col>
            <v-col cols="12" class="py-0 mb-2">
              <div class="py-1 c-btn white">
                <span class="font-18 lightGreyText--text">{{
                  readMore ? data.note : data.note.slice(0, 200)
                }}</span
                ><span
                  v-if="!readMore && data.note.length > 200"
                  @click="readMore = true"
                  style="color: #89bbcb; font-size: 12px; cursor: pointer"
                >
                  read more
                </span>
              </div>
            </v-col>
          </v-row>

          <!-- website -->
          <v-row align="center" class="py-0 my-1">
            <!-- <v-col cols="2" class="py-0 mt-1 mb-2">
              <span class="font-20 d-block black--text font-500">{{
                $t("website")
              }}</span>
            </v-col> -->
            <v-col cols="5" class="py-0 mt-1 mb-2">
              <div class="text-center py-2 c-btn white url_v">
                <a
                  :href="data.website && data.website[0].url"
                  target="_blank"
                  class="d-flex align-item-center justify-center"
                >
                  <img
                    width="20"
                    height="20"
                    class="mx-2"
                    :src="require('@/assets/images/icons/link.png')"
                    alt=""
                  />
                  <span class="font-18 primary--text">{{
                    $t("visitLink")
                  }}</span>
                </a>
              </div>
            </v-col>

            <!-- <v-col cols="2" class="py-0 mt-1 mb-2">
              <span class="font-20 d-block black--text font-500">{{
                $t("url")
              }}</span>
            </v-col> -->
          </v-row>
        </v-col>
        <v-col cols="4" class="mt-4">
          <div class="text-center">
            <!-- chat -->
            <v-btn
              v-if="data.owner_id != userID"
              class="c-btn mx-1 px-1"
              color="primary"
              height="40"
              @click="messageDialog = true"
            >
              <img
                :src="require('@/assets/images/icons/chat-white.png')"
                alt=""
              />
            </v-btn>

            <!-- info -->
            <v-btn
              class="c-btn mx-1 px-1 py-1"
              color="primary"
              height="40"
              @click="reportDialog = true"
            >
              <img :src="require('@/assets/images/icons/info.png')" alt="" />
            </v-btn>

            <!-- place bid -->
            <v-btn
              v-if="data.owner_id != userID"
              class="c-btn mx-1"
              color="primary"
              min-width="150"
              height="40"
              @click="openPlaceDialog"
            >
              <span
                v-if="data.status == 'pending'"
                class="white--text font-300 font-20"
                >{{ $t("placeBid") }}</span
              >
              <span v-else class="white--text font-300 font-20">{{
                $t("waiting")
              }}</span>
            </v-btn>
          </div>
        </v-col>

        <!-- origin address -->
        <v-row class="my-10">
          <v-col cols="5">
            <v-col cols="7" v-if="data.country_origin">
              <h6 class="py-2">
                <span class="font-25 primary--text font-500">
                  <img
                    width="15"
                    height="15"
                    class="mx-1"
                    :src="require('@/assets/images/icons/address.png')"
                    alt=""
                  />{{ $t("originAddress") }}</span
                >
              </h6>
              <ul class="mx-10">
                <li>
                  {{ data.country_origin }}
                </li>
                <li>
                  {{ data.city_origin }}
                </li>
                <li>
                  {{ data.address_origin }}
                </li>
              </ul>
            </v-col>
            <!-- destination address -->
            <v-col cols="5" v-if="data.country_destination">
              <div class="py-2">
                <span class="font-25 primary--text font-500"
                  ><img
                    width="15"
                    height="15"
                    class="mx-1"
                    :src="require('@/assets/images/icons/address.png')"
                    alt=""
                  />{{ $t("destinationAddress") }}</span
                >
              </div>
              <ul class="mx-10">
                <li>
                  {{ data.country_destination }}
                </li>
                <li>
                  {{ data.city_destination }}
                </li>
                <li>
                  {{ data.address_destination }}
                </li>
              </ul>
            </v-col>
          </v-col>
          <!-- {{ data }} -->

          <!-- map -->
          <v-col cols="7" v-if="markers[0].lng">
            <div class="map-cover w-100 h-100">
              <GmapMap
                :center="markers[0]"
                :zoom="8"
                style="width: 100%; height: 350px"
                @click="addLocation($event, 'destination')"
                map-type-id="roadmap"
              >
                <GmapMarker
                  v-for="(marker, i) in markers"
                  :key="i"
                  :clickable="true"
                  :draggable="true"
                  :position="{ lat: marker.lat, lng: marker.lng }"
                />
                <gmap-polyline
                  :key="keyUpdated"
                  v-bind:path.sync="markers"
                  v-bind:options="{ strokeColor: 'red' }"
                >
                </gmap-polyline>
              </GmapMap>
            </div>
          </v-col>
        </v-row>
      </v-row>
      <!-- buttons -->
      <!-- <div class="text-center mt-10 mb-14">
        <v-btn
          v-if="data.owner_id != userID"
          class="c-btn mx-3 px-2 py-1"
          color="secondary"
          height="40"
          @click="messageDialog = true"
        >
          <img :src="require('@/assets/images/icons/chat-white.png')" alt="" />
        </v-btn>
        <v-btn
          class="c-btn mx-3 px-2 py-1"
          color="secondary"
          height="40"
          @click="reportDialog = true"
        >
          <img :src="require('@/assets/images/icons/info.png')" alt="" />
        </v-btn>

        <v-btn
          v-if="data.owner_id != userID"
          class="c-btn mx-3"
          color="secondary"
          min-width="240"
          height="40"
          @click="openPlaceDialog"
        >
          <span
            v-if="data.status == 'pending'"
            class="white--text font-300 font-20"
            >{{ $t("placeBid") }}</span
          >
          <span v-else class="white--text font-300 font-20">{{
            $t("waiting")
          }}</span>
        </v-btn>
      </div> -->
    </div>

    <!-- bid dialog -->
    <v-dialog
      v-model="bidDialog"
      max-width="400"
      content-class="white"
      :key="bidDialog"
    >
      <place-bid @next="nextHandler"></place-bid>
    </v-dialog>

    <!-- wait dialog -->
    <v-dialog v-model="waitDialog" max-width="400" content-class="white">
      <shipment-wait @close="$router.replace('/')"></shipment-wait>
    </v-dialog>

    <!-- wait dialog -->
    <v-dialog v-model="waitBidDialog" max-width="400" content-class="white">
      <wait-bide @close="waitBidDialog = false"></wait-bide>
    </v-dialog>

    <!-- message dialog -->
    <v-dialog v-model="messageDialog" max-width="400" content-class="white">
      <message-dialog
        @close="messageDialog = false"
        :user="data"
        :key="messageDialog"
        messageDialog
      ></message-dialog>
    </v-dialog>

    <!-- report dialog -->
    <v-dialog v-model="reportDialog" max-width="400" content-class="white">
      <report-dialog
        @close="reportDialog = false"
        :user="data"
        :key="reportDialog"
        :item="data"
      ></report-dialog>
    </v-dialog>
  </div>
</template>

<script>
// import SectionHead from "../../../components/core/SectionHead.vue";
import PlaceBid from "../../../components/dialogs/PlaceBid.vue";
import ShipmentWait from "../../../components/dialogs/ShipmentWait.vue";
import MessageDialog from "../../../components/dialogs/Message.vue";
import ReportDialog from "../../../components/dialogs/Report.vue";
import { mapGetters } from "vuex";
import WaitBide from "../../../components/dialogs/WaitBide.vue";
export default {
  components: {
    // SectionHead,
    PlaceBid,
    ShipmentWait,
    MessageDialog,
    ReportDialog,
    WaitBide,
  },
  data: () => ({
    readMore: false,
    rating: 4,
    data: {},
    bidDialog: false,
    waitDialog: false,
    waitBidDialog: false,
    messageDialog: false,
    reportDialog: false,
    location: {},
    markers: [
      { lng: null, lat: null },
      { lng: null, lat: null },
    ],
    keyUpdated: false,
    obj: {},
    loading: false,
  }),
  watch: {
    $route: {
      async handler() {
        await this.getData();
      },
    },
  },
  computed: {
    ...mapGetters(["categories"]),
    userID() {
      return localStorage.getItem("userID");
    },
  },
  methods: {
    openPlaceDialog(item) {
      if (this.data.status == "waiting") {
        this.waitBidDialog = true;
        return;
      }
      this.shipment_id = item.id;
      if (localStorage.getItem("role") != "bringer") {
        this.$router.push("/verification");
      } else {
        this.bidDialog = true;
      }
    },
    async nextHandler(value) {
      this.obj.price_shipment = value.price_shipment;
      this.obj.trip_id = value.trip_id;
      this.bidDialog = false;
      this.waitDialog = true;

      await this.submitBid();
    },
    async submitBid() {
      let formData = new FormData();
      formData.append("shipment_id", this.data.id);
      formData.append("price_shipment", this.obj.price_shipment);
      formData.append("trip_id", this.obj.trip_id);
      if (this.data.order_id) {
        formData.append("order_id", this.data.order_id);
      }
      let res = await this.$axios.post("sendBidAsBringer", formData);
      if (res.data) {
        this.$store.dispatch("showSnack", {
          text: "Place Bid Successfully",
          color: "success",
        });
        this.waitDialog = true;
      } else {
        this.$store.dispatch("showSnack", {
          text: res.message,
          color: "error",
        });
      }
    },
    async getData() {
      this.loading = true;
      let { data } = await this.$axios.get(
        `/${this.$route.name}/` + this.$route.params.id
      );
      if (data.data) {
        this.loading = false;
        this.data = data.data[this.$route.name];

        if (data.data.product) {
          this.data.budget = data.data.product.price;
          this.data.note = data.data.product.description;
          this.data.category = this.categories.filter(
            (cat) => cat.id == data.data.product.category_id
          )[0];
        } else {
          this.markers[0].lat = +data.data.shipment.address_lat_origin;
          this.markers[0].lng = +data.data.shipment.address_long_origin;
          this.markers[1].lat = +data.data.shipment.address_lat_destination;
          this.markers[1].lng = +data.data.shipment.address_long_destination;
          this.keyUpdated = !this.keyUpdated;
        }
        if (localStorage.getItem("userID") == this.data.owner_id) {
          this.$router.replace("/shipment/details/" + this.item.id);
        }
      }
    },
  },
  async created() {
    await this.getData();
  },
};
</script>

<style lang="scss">
.details-ship {
  .url_v {
    border: 1px solid $primary !important;
  }

  .v-carousel__controls {
    height: 25px !important;
    width: 240px !important;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .v-carousel__controls > .v-item-group {
    // margin-bottom: -100px;
  }
}
.img-cover-main {
  height: 450px;
}
</style>
